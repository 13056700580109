import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { AuthProvider } from './context/AuthProvider';
import { MessageProvider } from './context/MessageProvider';
import { UserProvider } from './context/UserProvider';
import ProtectedRoute from './components/ProtectedRoute';

import Navbar from './components/Navbar';
import MessageDisplay from './components/MessageDisplay';
import AutoLoginWrapper from './context/AutoLoginWrapper';

import ProfilePage from './pages/ProfilePage';

import OrderGrid from './pages/OrderGrid';
import EmailLogGrid from './pages/EmailLogGrid';
import ErrorLogGrid from './pages/ErrorLogGrid';
import RequestLogGrid from './pages/RequestLogGrid';
import WebhookLogGrid from './pages/WebhookLogGrid';
import CronJobLogGrid from './pages/CronJobLogGrid';

import './App.css';

import { LicenseInfo } from '@mui/x-license';

//TODO - MOVE THIS TO AN ENV FILE
LicenseInfo.setLicenseKey(
  '0ca31bc16cf762d08dccb4c4c270b3b7Tz05Njc1MCxFPTE3NTU5NzY0MjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
);

function App() {
    return (
        <Router>
            <MessageProvider>
                <AuthProvider>
                    <UserProvider>
                        <AutoLoginWrapper>
                            <div className="App">
                                <Navbar />
                                <MessageDisplay />
                                <Routes>
                                    <Route path="/" element={<Navigate to="/orders" replace />} />
                                    <Route path="/profile" element={<ProtectedRoute element={ProfilePage} />} />
                                    <Route path="/orders" element={<ProtectedRoute element={OrderGrid} />} />
                                    <Route path="/webhooks" element={<ProtectedRoute element={WebhookLogGrid} />} />
                                    <Route path="/clientrequests" element={<ProtectedRoute element={RequestLogGrid} />} />
                                    <Route path="/cron" element={<ProtectedRoute element={CronJobLogGrid} />} />
                                    <Route path="/errors" element={<ProtectedRoute element={ErrorLogGrid} />} />
                                    <Route path="/emails" element={<ProtectedRoute element={EmailLogGrid} />} />
                                </Routes>
                            </div>
                        </AutoLoginWrapper>
                    </UserProvider>
                </AuthProvider>
            </MessageProvider>
        </Router>
    );
}

export default App;

