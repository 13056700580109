// hooks/useDataGrid.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import useAuth from './useAuth';
import { useMessage } from '../context/MessageProvider';
import { useSocket } from '../hooks/useSocket';

import { getHeaders } from '../utility/auth';
import config from '../config.json';

function useDataGrid(dataType) {
    const baseUrl = config.apiUrl;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [queryOptions, setQueryOptions] = useState({
        sortModel: [{ field: 'id', sort: 'desc' }],
    });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 500,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
        const cookieValue = Cookies.get(`${dataType}ColumnVisibility`);
        return cookieValue ? JSON.parse(cookieValue) : {};
    });

    const { subscribeToOrders } = useSocket();
    
    useEffect(() => {
        const handleOrderUpdate = (updatedOrder) => {
            console.log('Order Updated:', updatedOrder);
            
            setData((prevData) => {
                // Find the index of the order with the same id
                const index = prevData.findIndex(order => order.id === updatedOrder.id);
                
                if (index !== -1) {
                    // Order exists, update it
                    const updatedData = [...prevData];
                    updatedData[index] = { ...updatedData[index], ...updatedOrder };
                    return updatedData;
                } else {
                    // New order, add it to the top
                    return [updatedOrder, ...prevData];
                }
            });
        };

        // Subscribe to order updates
        const unsubscribe = subscribeToOrders(handleOrderUpdate);

        // Cleanup subscription on unmount
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [subscribeToOrders]);

    
    const { logout } = useAuth();
    const { sendMessage } = useMessage();

    const reload = () => {
        setReloadTrigger((prevTrigger) => prevTrigger + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}record/${dataType}`, {
                    headers: getHeaders(),
                    params: {
                        ...queryOptions,
                        page: paginationModel.page,
                        pageSize: paginationModel.pageSize,

                    },
                });
                setData(response.data.rows);
                setRowCount(response.data.totalRowCount);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    await logout();
                    sendMessage('Session expired. Please login again.', 'error');
                } else {
                    console.error(err);
                    sendMessage('Something went wrong.', 'error');
                }
            }
        };

        fetchData();
// eslint-disable-next-line
    }, [dataType, queryOptions, paginationModel, baseUrl, reloadTrigger]);

    const onFilterChange = (filterModel) => {
        setQueryOptions((prevOptions) => ({
            ...prevOptions,
            filterModel,
        }));
    };

    const onSortModelChange = (sortModel) => {
        setQueryOptions((prevOptions) => ({
            ...prevOptions,
            sortModel,
        }));
    };

    const onPaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    const onColumnVisibilityModelChange = (newModel) => {
        setColumnVisibilityModel(newModel);
        Cookies.set(`${dataType}ColumnVisibility`, JSON.stringify(newModel), { path: '/' });
    };

    return {
        data,
        loading,
        rowCount,
        paginationModel,
        onFilterChange,
        onSortModelChange,
        onPaginationModelChange,
        reload,
        reloadTrigger,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
    };
}

export default useDataGrid;
