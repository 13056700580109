import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

function ProtectedRoute({ element: Component }) {
    const { auth } = useAuth();
    return auth?.token ? <Component /> : <Navigate to="/" replace />;
}

export default ProtectedRoute;
