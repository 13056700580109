import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Button,
    Dialog,
    TextField,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import useAuth from '../hooks/useAuth';
import useUser from '../hooks/useUser';

function Navbar() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [developerOpen, setDeveloperOpen] = useState(false);

    const { auth, login, logout } = useAuth();
    const { user, getUserInfo, clearUser } = useUser();

    const loginFormRef = useRef(null);

    useEffect(() => {
        if (auth?.token && !user?.user?.id) {
            getUserInfo();
        }
        // eslint-disable-next-line
    }, [auth, user]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await login({ email, password, device: navigator.userAgent.toLowerCase() });
            setLoginOpen(false);
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            clearUser();
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const drawerList = () => {
        const handleDeveloperClick = (event) => {
            event.stopPropagation();
            setDeveloperOpen(!developerOpen);
        };

        return (
            <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                <List>
                    {[
                        { text: 'Orders', path: '/orders' },
                        { text: 'Profile', path: '/profile' },
                        { text: 'Emails', path: '/emails' },
                    ].map(({ text, path }) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton component={Link} to={path}>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                    <ListItem button onClick={handleDeveloperClick}>
                        <ListItemText primary="Developer" />
                        {developerOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={developerOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {[
                                { text: 'Webhooks', path: '/webhooks' },
                                { text: 'Client Requests', path: '/clientrequests' },
                                { text: 'Cron Jobs', path: '/cron' },
                                { text: 'Errors', path: '/errors' },
                            ].map(({ text, path }) => (
                                <ListItem key={text} disablePadding sx={{ pl: 4 }}>
                                    <ListItemButton component={Link} to={path}>
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            </div>
        );
    };

    const display = auth?.user && user?.user;
    return (
        <AppBar position="static">
            <Toolbar>
                {display ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={toggleDrawer(true)}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerList()}
                        </Drawer>
                        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            Welcome{user?.user?.first_name ? `, ${user?.user?.first_name}` : ', User'}
                        </Typography>
                        <Button color="inherit" onClick={handleLogout}>
                            Logout
                        </Button>
                    </>
                ) : (
                    <Button color="inherit" onClick={() => setLoginOpen(true)}>
                        Login
                    </Button>
                )}
                <Dialog open={loginOpen} onClose={() => setLoginOpen(false)}>
                    <form ref={loginFormRef}>
                        <DialogContent>
                            <TextField
                                label="Email"
                                type="email"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                sx={{ mt: 2 }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleLogin}>Login</Button>
                            <Button onClick={() => setLoginOpen(false)}>Cancel</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;