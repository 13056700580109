import axios from 'axios';
import Cookies from 'js-cookie';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../context/AuthProvider';
import { useUserContext } from '../context/UserProvider';

import config from '../config.json';
import { getHeaders } from '../utility/auth';

function useAuthAction() {
    const baseUrl = config.apiUrl;
    const [auth, setAuthStateWithMessage] = useAuthContext();
    const [, setUserState] = useUserContext();
    const navigate = useNavigate();

    const login = async (credentials) => {
        try {
            const response = await axios.post(`${baseUrl}auth/login`, credentials);
            const { token, user_id } = response.data;
            if (token) {
                setAuthStateWithMessage(
                    { token, user: user_id },
                    'Login successful',
                    'success'
                );
                Cookies.set('token', token, { path: '/' });
                Cookies.set('user_id', user_id, { path: '/' });
                navigate('/orders');
            }
            return true;
        } catch (error) {
            console.error('Login failed:', error);
            setAuthStateWithMessage({}, 'Login failed', 'error');
        }
    };

    const logout = async () => {
        try {
            // eslint-disable-next-line
            const _response = await axios.post(`${baseUrl}auth/logout`, {}, { headers: getHeaders() });
        } catch(err){
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                console.debug('Already logged out.');
            }
        }

        try {
            Cookies.remove('token', { path: '/' });
            Cookies.remove('user_id', { path: '/' });
            
            setAuthStateWithMessage({ token: null, user: null }, 'You have been logged out', 'success');
            setUserState({user:{}});
            navigate('/');
            
            return true;
        } catch (error) {
            console.error('Logout failed:', error);
            setAuthStateWithMessage({}, 'Logout failed', 'error');
        }
    };

    return { login, logout, auth };
}

export default useAuthAction;