import { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import config from '../config.json';
import { getJwtToken } from '../utility/auth';
import { useAuthContext } from '../context/AuthProvider';

export const useSocket = () => {
    const socketRef = useRef(null);
    const [auth] = useAuthContext();

    useEffect(() => {
        const token = getJwtToken();
        if (!token) return;

        // Compute the server URL
        const baseApiUrl = config.apiUrl.endsWith('/')
            ? config.apiUrl.slice(0, -1)
            : config.apiUrl;
        const serverUrl = baseApiUrl.replace('/api-dashboard', '');

        console.log('Socket.IO Server URL:', serverUrl);

        // Initialize socket connection
        socketRef.current = io(serverUrl, {
            path: '/api-dashboard/socket.io', // Match the server path
            auth: { token },
            transports: ['websocket', 'polling'],
            reconnectionAttempts: 5,
            reconnectionDelay: 1000
        });

        socketRef.current.on('connect', () => {
            console.debug('Socket connected');
        });

        socketRef.current.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        socketRef.current.on('disconnect', () => {
            console.debug('Socket disconnected');
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, [auth?.user?.shop_id]);

    const subscribeToOrders = (callback) => {
        if (!socketRef.current) return;

        socketRef.current.on('order_update', callback);
        return () => socketRef.current?.off('order_update', callback);
    };

    return {
        socket: socketRef.current,
        subscribeToOrders
    };
};
